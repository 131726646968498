import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, Autocomplete, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { MaterialTable } from '@lexcelon/react-util';
import { setError } from '../../../../alerts';
import { BackToButton } from '@lexcelon/react-util';
import qs from 'qs';

import { listTests, getPractice } from '../../../../api';

const COLUMNS = [
  {
    title: 'Start Time',
    remoteField: 'timestamp',
    value: rowData => rowData.getTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short' }),
    customSort: direction => ['timestamp', direction === 'asc' ? 'desc' : 'asc'] // Flip the direction of sorting
  },
  {
    title: 'Practice',
    remoteField: '$practice.identifier$',
    value: rowData => rowData.getPractice().getIdentifier(),
    disableSort: true
  },
  {
    title: 'Animal',
    remoteField: '$animal.name$',
    value: rowData => rowData.getAnimal()?.getName(),
    disableSort: true,
  },
  {
    title: 'Client Name',
    value: rowData => rowData.getAnimal()?.getOwnerFullName(),
    disableSort: true,
  },
  {
    title: 'Animal Species',
    remoteField: '$animal.animal_species_id$',
    value: rowData => rowData.getAnimal()?.getAnimalSpecies()?.getName(),
    disableSort: true,
  },
  {
    title: 'Test Type',
    remoteField: 'testTypeId',
    value: rowData => `${rowData.getTestType()?.getTestTypeGroup()?.getName() ?? rowData.getTestType()?.getName()} [${rowData.getTestType()?.getIsDemo() ? 'Training' : 'Normal'}]`,
    disableSort: true,
  },
];

class MonthlyPracticeTests extends Component {
  constructor(props) {
    super(props);
    this.practiceId = this.props.match?.params?.id;

    this.state = {
      allMonths: [],
      selectedMonth: null
    };
  }

  componentDidMount() {
    const allMonths = this.getCompletedMonthsList();
    let selectedMonth = allMonths[allMonths.length - 1];
    let parsedMonth = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.month;
    if (parsedMonth != null) {
      selectedMonth = DateTime.fromISO(parsedMonth);
    }
    this.setState({ allMonths, selectedMonth });

    getPractice(this.practiceId).then(practice => {
      this.setState({ practice });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve practice.');
    });
  }

  getCompletedMonthsList() {
    const start = DateTime.fromObject({ year: 2023, month: 11 });
    const now = DateTime.now();
    const end = now.minus({ months: now.day > 1 ? 0 : 1 });
    let months = [];
  
    for (let dt = start; dt <= end; dt = dt.plus({ months: 1 })) {
      months.push(dt.startOf('month'));
    }
  
    return months;
  }

  handleMonthChange = (_, selectedMonth) => {
    this.setState({ selectedMonth });
  };

  render() {
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>{this.state.practice?.getName() ? this.state.practice?.getName() + ' ' : ''}Tests</Typography>

        <Autocomplete
          disablePortal
          options={this.state.allMonths}
          style={{ width: '300px', marginBottom: '1em' }}
          value={this.state.selectedMonth}
          getOptionLabel={(option) => option.toFormat('MMMM yyyy')}
          onChange={this.handleMonthChange}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label='Month'
              variant='filled'
            />
          )}
        />

        {this.state.selectedMonth != null &&
        <MaterialTable
          title='Tests'
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where: {
                    ...where,
                    timestamp: {
                      ['$between']: [this.state.selectedMonth.startOf('month').toISO(), this.state.selectedMonth.endOf('month').toISO()]
                    },
                    practiceId: this.practiceId,
                    status: 'COMPLETED',
                    ['$testType.is_demo$']: false,
                    ['$testType.is_coming_soon$']: false,
                    ['$testType.is_active$']: true,
                    parentTestId: null,
                    jointParentTestId: null
                  },
                  order,
                };
                listTests({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          options={{
            pageSize: 20,
            flipHorizontalScroll: true,
          }}
        />}

      </div>
    );
  }
}

MonthlyPracticeTests.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.object.isRequired,
};

export default withRouter(MonthlyPracticeTests);
